.ssh-connect-dialog {
    min-height: 298px;
}

.ssh-connect-dialog .ms-Dialog-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
}

.ssh-connect-dialog .ms-Dialog-subText {
    font-size: 14px;
    line-height: 20px;
}

.student-vm-stop-dialog .ms-Dialog-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
}

.student-vm-stop-dialog .ms-Dialog-subText {
    font-size: 14px;
    line-height: 20px;
}

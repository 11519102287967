.home-button__logo-icon {
    height: 20px;
}

.home-button__logo-icon svg {
    width: 20px;
    height: 20px;
}

.home-button__logo-brand {
    margin-left: 12px;
}
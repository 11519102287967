#page-footer-container {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: auto;
    padding-right: 15px;
    padding: 5px 15px 5px 0;
}

.page-footer__items {
    margin-right: 10px;
    flex: 0 0 auto;
    box-sizing: border-box;
}

.page-footer__links:hover {
    color: white !important;
    text-decoration: none !important;
}

#lab-list-intro-dialog-close-button {
    position: absolute;
    z-index: 999;
    right: 0;
    background-color: transparent;
}

.lab-list-intro__carousel-item {
    max-width: 730px;
    min-height: 550px;
    background: #ffffff;
    text-align: center;
}

.lab-list-intro__carousel-item__animation {
    max-height: 211px;
}

.lab-list-intro__carousel-item__content {
    min-height: 275px;
}

.lab-list-intro__content-container {
    width: 100%;
    height: 100%;
}

.lab-list-intro__content-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 12px;
}

.lab-list-intro__content-text {
    max-width: 631px;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 12px;
    margin-right: 50px;
    margin-left: 50px;
}

.lab-list-intro__extra-content {
    justify-items: center;
}

/* Dialog stuff */

.lab-list-intro-dialog .ms-Dialog-title {
    display: none;
}

.lab-list-intro-dialog .ms-Dialog-inner {
    padding: 0;
}

.lab-list-intro-button-back {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
}

.lab-list-intro-button-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
}

/* Carousel stuff */

.carousel .slide {
    background: #ffffff;
}

.carousel .carousel-status {
    display: none;
}

.carousel .control-dots {
    background: #ffffff;
    margin-top: 0;
    margin-bottom: 45px;
}

.carousel .control-dots .dot.selected {
    background: #0078d4;
}

.carousel .control-dots .dot {
    background: #e1dfdd;
    box-shadow: none;
    width: 12px;
    height: 12px;
    margin: 0px 5px;
}

.user-quota__description {
    margin-bottom: 30px;
}

.user-quota__calculation {
    margin-top: 30px;
}

.user-quota__quota-calc-label {
    width: 235px;
    display: inline-block;
}

.user-quota__total {
    font-weight: bold;
}

.user-quota__warning {
    margin-top: 30px;
}

.user-quota__warning-icon {
    display: inline-block;
    width: 10%;
    vertical-align: top;
    padding-top: 3px;
}

.user-quota__warning-message {
    display: inline-block;
    width: 90%;
}

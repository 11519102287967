#settings-page-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#settings-page-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#settings-page-header {
    height: fit-content;
    width: 100%;
}

#settings-page-body {
    margin-top: 32px;
}

#lab-list-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#lab-list-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#lab-list-header {
    height: auto;
    width: 100%;
}

.lab-list {
    margin-top: 19px;
    padding-bottom: 10px;
    padding-left: 21px;
    padding-right: 21px;
}

div .lab-list__card {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 329px;
    max-width: 329px;
    height: 212px;
    border: 1px solid transparent;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
    border-radius: 2px;
}

.lab-list__card-body {
    height: 100%;
    padding: 20px;
    position: relative;
}

.lab-list__card-body-title-section {
    display: flex;
}

.lab-list__card-body-icon-section {
    display: flex;
    position: relative;
}

.lab-list__card-body-icon {
    font-size: 50px;
}

.lab-list__card-body-circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    top: 35px;
    right: -20px;
    color: white;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
}

.lab-list__card-body-failed-circle {
    background: #d13438 !important;
}

div .lab-list__card-title {
    font-weight: 600;
    font-size: 16px;
    padding-left: 35px;
    display: flex;
    width: 100%;
}

/* https://css-tricks.com/line-clampin/ */
.lab-list__card-title-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

.lab-list__card-title-bubble-container {
    margin-left: auto;
}

.lab-list__card-title-bubble {
    background-color: #f3f2f1;
    border-radius: 15px;
    width: fit-content;
    padding: 1px 6px 3px 6px;
    font-size: 12px;
    font-weight: normal;
}

.lab-list__card-body-info {
    padding-bottom: 15px;
    padding-right: 15px;
    position: absolute;
    bottom: 0;
}

.lab-list__card-body-info--emphasis {
    font-weight: 600;
}

.lab-list__card-divider {
    margin: 0 6px;
    height: 1px;
}

.lab-list__card-footer {
    margin-left: auto;
}

div .lab-list__card-footer-menu {
    border: none;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
    border-radius: 2px;
}

div .lab-list__card-footer-menu-button {
    padding: 0px;
}

.lab-list__card-footer-menu-items {
    padding: 3px 0px 3px 0px;
}

/*
    This is what makes the list a GRID
    It's a little fragile and will need to be updated as we update office fabric
    Explanation: https://github.com/OfficeDev/office-ui-fabric-react/issues/6559
*/
.ms-List-surface,
.ms-List-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* separating vNext styles */

.lab-list__card-body-vnext {
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
}
.lab-list__card-body-title-section-vnext {
    display: flex;
}
.lab-list__card-body-icon-section-vnext {
    display: flex;
}

.lab-list__card-body-circle-vnext {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
}
.lab-list__card-body-failed-circle-vnext {
    background: #d13438 !important;
}
div.lab-list__card-title-vnext {
    font-weight: 600;
    font-size: 16px;
    padding-left: 2px;
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.lab-list__card-title-subtitle-container {
    display: flex;
    flex-direction: column;
}

.lab-list__card-subtitle-vnext {
    font-size: 12px;
    line-height: 16px;
    color: #605e5c;
    font-weight: 300;
}

.lab-list__card-title-overflow-vnext {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.lab-list__card-title-bubble-container-vnext {
    margin-left: auto;
    display: flex;
}

.lab-list__card-title-bubble-vnext {
    background-color: #f3f2f1;
    border-radius: 2px;
    width: fit-content;
    padding: 1px 6px 1px 6px;
    font-size: 12px;
    font-weight: normal;
}

.lab-list__card-body-info-vnext {
    margin-top: auto; /* move it to the bottom */
    padding-bottom: 15px;
    padding-right: 15px;
}
.lab-list__card-body-info-vnext-quota {
    padding-top: 3px;
}
.padding-right {
    padding-right: 5px;
}

#lab-app-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#lab-app-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#lab-app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 0;
}

#lab-app-nav {
    flex-grow: 0;
}

#lab-app-page {
    flex-grow: 1;
    display: flex;
}

.lab-app-focus-zone {
    width: 100%;
    height: 100%;
}

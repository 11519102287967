/*
  Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
*/
@font-face {
    font-family: 'FabricMDL2Icons';
    src: url('../fonts/fabric-icons.woff') format('woff');
}

.ms-Icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'FabricMDL2Icons';
    font-style: normal;
    font-weight: normal;
    speak: none;
}

.ms-Icon--Edit:before {
    content: '\E70F';
}
.ms-Icon--Add:before {
    content: '\E710';
}
.ms-Icon--More:before {
    content: '\E712';
}
.ms-Icon--Settings:before {
    content: '\E713';
}
.ms-Icon--Mail:before {
    content: '\E715';
}
.ms-Icon--People:before {
    content: '\E716';
}
.ms-Icon--Stop:before {
    content: '\E71A';
}
.ms-Icon--Link:before {
    content: '\E71B';
}
.ms-Icon--Filter:before {
    content: '\E71C';
}
.ms-Icon--Search:before {
    content: '\E721';
}
.ms-Icon--Refresh:before {
    content: '\E72C';
}
.ms-Icon--Delete:before {
    content: '\E74D';
}
.ms-Icon--Play:before {
    content: '\E768';
}
.ms-Icon--WindowsLogo:before {
    content: '\E782';
}
.ms-Icon--Warning:before {
    content: '\E7BA';
}
.ms-Icon--TVMonitor:before {
    content: '\E7F4';
}
.ms-Icon--View:before {
    content: '\E890';
}
.ms-Icon--Upload:before {
    content: '\E898';
}
.ms-Icon--GotoToday:before {
    content: '\E8D1';
}
.ms-Icon--Permissions:before {
    content: '\E8D7';
}
.ms-Icon--Clock:before {
    content: '\E917';
}
.ms-Icon--Timer:before {
    content: '\E91E';
}
.ms-Icon--Info:before {
    content: '\E946';
}
.ms-Icon--PeopleAdd:before {
    content: '\EA15';
}
.ms-Icon--Ringer:before {
    content: '\EA8F';
}
.ms-Icon--CirclePlus:before {
    content: '\EAEE';
}
.ms-Icon--Money:before {
    content: '\EAFD';
}
.ms-Icon--EditMirrored:before {
    content: '\EB7E';
}
.ms-Icon--ThisPC:before {
    content: '\EC4E';
}
.ms-Icon--DeveloperTools:before {
    content: '\EC7A';
}
.ms-Icon--DateTime:before {
    content: '\EC92';
}
.ms-Icon--Export:before {
    content: '\EDE1';
}
.ms-Icon--DateTimeMirrored:before {
    content: '\EE93';
}
.ms-Icon--ConnectVirtualMachine:before {
    content: '\EE9D';
}
.ms-Icon--ClipboardList:before {
    content: '\F0E3';
}
.ms-Icon--ClipboardListMirrored:before {
    content: '\F0E4';
}
.ms-Icon--StatusCircleQuestionMark:before {
    content: '\F142';
}
.ms-Icon--ViewDashboard:before {
    content: '\F246';
}
.ms-Icon--MoreVertical:before {
    content: '\F2BC';
}
.ms-Icon--EngineeringGroup:before {
    content: '\F362';
}
.ms-Icon--TestBeaker:before {
    content: '\F3A5';
}
.ms-Icon--TestBeakerSolid:before {
    content: '\F3A6';
}
.ms-Icon--LinuxLogo32:before {
    content: '\F5D3';
}
.ms-Icon--Warning12:before {
    content: '\F62F';
}
.ms-Icon--DisconnectVirtualMachine:before {
    content: '\F873';
}

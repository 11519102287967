.carouselContainer {
    background: none;
    box-shadow: none;
    outline: none;

    /*width: 100%;
    height: 100%;*/
}

/*.carouselContainer:global(.ms-Modal-scrollableContent) {
    width: 100%;
    height: 100%;
}*/

.carouselScroll {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-width: 1210px;
    height: 100%;
    min-height: 540px;
}

.gutter {
    width: 80px;
    margin: 80px;
}

.hideButton {
    display: none;
}

.previousCard,
.nextCard {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18);
    transition: background-color 0.125s ease-in-out;
}

.previousCard i,
.nextCard i {
    font-size: 40px;
}

.previousCard:hover,
.nextCard:hover {
    background-color: #edebe9;
}

.previousCard i {
    transform: translateX(-4px);
}

.nextCard i {
    transform: translateX(4px);
}

.mainContent {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 730px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18);
}

.closeButtonContainer {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    box-shadow: none;
}

.closeButton {
    width: 100%;
    height: 100%;
    background-image: url('./dismissal-x.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px 17px;
    background-color: transparent;
    cursor: pointer;
    border: none;
}

.closeButton:hover {
    opacity: 0.5;
}

.paginationContainer {
    width: 730px;
    height: 500px;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
}

.cardPagination {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: auto;
}

/* ---------------- Date header ---------------- */

/* Make sure entire header is certain color */
thead {
    background-color: #faf9f8;
}

/* Formats date header */
.fc-toolbar-chunk .fc-toolbar-title {
    font-size: 18px;
    line-height: 24px;
}

/* Formatting for table header (dates) */
.fc th {
    text-align: left;
    border: 0px;
    height: 32px;
    vertical-align: middle;
    font-weight: 500;
    text-indent: 14px;
}

/* Background color needs to be defined for Firefox */
.fc-scrollgrid-section-header {
    background-color: #faf9f8;
}

/* Bottom border for table header (dates) */
.fc-scrollgrid-section-header tr {
    border-bottom: 1px solid #ddd;
}

/* Adjusts each date header (Monday, Tuesday) into the correct location  */
.fc .fc-col-header-cell-cushion {
    padding: 0px;
    display: inline;
}

/* Remove highlight for selected day */
.fc-timegrid-body .fc-day-today {
    background-color: transparent !important;
}

/* Styling for today's date header */
th.fc-day-today .fc-scrollgrid-sync-inner {
    background-color: white;
    border-top: 4px solid #0078d4;
    height: 100%;
    color: #0078d4;

    /* Bug in Firefox requires next line for bottom-border https://bugzilla.mozilla.org/show_bug.cgi?id=688556 */
    background-clip: content-box;
}

.schedule__teams-calendar th.fc-day-today .fc-scrollgrid-sync-inner {
    border-top: 4px solid #7173b0;
    color: #6264a7;
}

.fc-col-header-cell-cushion {
    vertical-align: middle;
}

/* ---------------- Calendar grid ---------------- */

/* Hide scrollbar in thead */
.fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
}

/* Remove top and left borders from entire calendar */
.fc-timegrid .fc-scrollgrid {
    border: 0px;
}

/* Remove right border from entire calendar */
.fc-scrollgrid-section-header td,
.fc-scrollgrid-section-body td {
    border-right: 0px;
}

/* Using outline on this element doesn't render properly in Chrome, so use border instead */
.fc-scroller-liquid-absolute:focus-visible {
    border: 1px solid #605e5c;
    outline: none;
}

/* Makes height of table cells bigger */
.fc-timegrid-body .fc-timegrid-slots td {
    height: 33px;
}

/* Makes 30 minute line dotted - this used to be dashed but something is causing this to render strangely now */
.fc .fc-timegrid-slot-minor {
    border-top-style: dotted;
}

/* Stylings for time axis */
.fc .fc-timegrid-slot-label {
    padding: 0px 16px 0px 0px;
    vertical-align: top;
    font-size: 12px;
}
.fc .fc-timegrid-slot-label-cushion {
    padding: 0px;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: left;
}

/* Don't overlap text on extreme zoom in */
th.fc-day-header.fc-widget-header {
    overflow-x: hidden;
}

/* ---------------- Events ---------------- */

/* Styling for events */
.fc-timegrid .fc-v-event {
    border: 0px;
    border-left: 5px solid #0a7cd4;
    background-color: #deebf8;
    color: #323130;
    padding-left: 9px;
    padding-top: 5px;
    font-size: 12px;
    position: absolute;
    z-index: 100;
}

/* Make cursor into pointer on hover over events */
.fc-timegrid .fc-v-event:hover {
    cursor: pointer;
}

/* Set focus outline for events */
.fc-timegrid .fc-v-event:focus-visible {
    outline: 1px solid #605e5c;
}

/* Font color for events */
.fc-v-event .fc-event-main {
    color: #323130;
}

/* Remove spacing around events */
.fc-direction-ltr .fc-timegrid-col-events {
    margin: 1px;
}
.fc-timegrid-event-harness {
    margin-bottom: 1px;
}

/* Remove curved edges around event */
.fc-event {
    border-radius: 0px;
}

/* Add recurring icon */
.fc-event--recurring .fc-event-main:after {
    font-family: 'FabricMDL2Icons';
    content: '\E895';
    font-size: 13px;
    color: #323130;
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin-bottom: 8px;
    margin-right: 8px;
}

/* Stylings for start only event */
.fc-timegrid .fc-event.fc-event--start-only {
    background-color: #dff6dd;
    height: 31px;
}

/* Stylings for stop only event */
.fc-timegrid .fc-event.fc-event--stop-only {
    background-color: #fed9cc;
    height: 31px;
}

/* Common stylings for start/stop only events */
.fc-event.fc-event--start-only:before,
.fc-event.fc-event--stop-only:before {
    background-color: white;
    content: '';
    width: 5px;
    height: 31px;
    position: absolute;
    bottom: 0px;
    left: -5px;
}

/* Border color for start only events */
.fc-event.fc-event--start-only:before {
    border: 1px solid #0b6a0b;
}

/* Border color for stop only events */
.fc-event.fc-event--stop-only:before {
    border: 1px solid #d13438;
}

/* ---------------- Buttons ---------------- */

/* Today button stylings */

/* Square button corners, transparent border ensures adding a border for other states doesn't make the button jump */
.fc .fc-button {
    border-radius: 0px;
    border: 1px solid inherit;
}

/* Hover state */
.fc .fc-button-primary:hover {
    background-color: #f3f2f1;
    color: #201f1e;
    border: 1px solid transparent;
}

/* Active (pressed) state */
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #edebe9;
    color: #201f1e;
    border: 1px solid transparent;
}

/* Fix border for focus state */
.fc .fc-button-primary:focus {
    box-shadow: none;
    outline: 1px solid #605e5c;
}

/* Remove combination focus/active (pressed) box shadow */
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
}

/* Replace right nav arrow with office fabric icon. */
.fc-icon-chevron-right:before {
    font-family: 'FabricMDL2Icons';
    content: '\E76C' !important;
    font-weight: bolder !important;
    font-size: inherit !important;
    top: inherit !important;
}

/* Replace left nav arrow with office fabric icon. */
.fc-icon-chevron-left:before {
    font-family: 'FabricMDL2Icons';
    content: '\E76B' !important;
    font-weight: bolder !important;
    font-size: inherit !important;
    top: inherit !important;
}

/* Remove -1px so that button focus state borders style correctly */
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    margin-left: 0px;
}

/* Button stylings */
.fc-toolbar-chunk .fc-button-primary,
.fc-toolbar-chunk .fc-button-primary:disabled,
.fc-button-group .fc-button {
    background-color: transparent;
    border: 1px solid transparent;
    text-transform: capitalize;
    font-weight: 400;
    color: #323130;
    font-size: 14px;
}

/* Button icon stylings */
.fc-button-group .fc-button .fc-icon {
    font-size: 16px;
    color: #0078d4;
}

.schedule__teams-calendar .fc-button-group .fc-button .fc-icon {
    color: #6264a7;
}

/* Disabled state of buttons */
.fc-toolbar-chunk .fc-button-primary:disabled {
    background-color: transparent;
    color: #a19f9d;
}

/* Stylings for today button icon */
.fc-button.fc-today-button:before {
    font-family: 'FabricMDL2Icons';
    content: '\E8D1';
    margin-right: 6px;
    font-size: 16px;
    vertical-align: sub;
    color: #0078d4;
}

.schedule__teams-calendar .fc-button.fc-today-button:before {
    color: #6264a7;
}

/* Disabled state of today button icon */
.fc-button:disabled.fc-today-button:before {
    color: #a19f9d;
}

/* Spacing between today button and navigation buttons */
.fc-toolbar > * > :not(:first-child) {
    margin-left: 10px;
}

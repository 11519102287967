#user-list-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#user-list-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#user-list-header {
    height: fit-content;
    width: 100%;
}

#user-list-body {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
}

/* UPGRADE-TODO: Find a better way to style the upload button after upgrade */
.user-list-upload-button {
    cursor: pointer;
    line-height: 32px;
    padding: 0;
    outline: none;
    font-weight: normal;
}

.user-list-upload-button:hover {
    background-color: rgb(244, 244, 244);
}

.user-list-upload-button:active {
    background-color: rgb(234, 234, 234);
}

.user-list__row {
    padding: 0;
}

.user-list__name-column {
    padding: 0;
}

.user-list__name-stack {
    max-height: 42px; /* Fix for weird Edge issue where the stack resizes */
    height: 100%;
    width: 100%;
}

.user-list__name-persona-stack-item {
    height: 100%;
    width: 28px;
    padding-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.user-list__name-text-stack-item {
    height: 100%;
    padding-top: 20px;
    max-width: calc(100% - 64px - 48px);
}

.user-list__name-action-stack-item {
    height: 100%;
    width: 32px;
}

.user-list__action-selected {
    display: flex;
}

.user-list__action-not-selected {
    display: none;
}

.user-list__row:hover .user-list__action-not-selected {
    display: flex;
}

.user-list__row:hover .user-list__action-not-selected:hover {
    background-color: #edebe9;
}

.user-list__filter-button .ms-ContextualMenu-icon {
    display: none;
}

.user-list__filter-clear-button .ms-Icon-placeHolder {
    display: none;
}

.ms-Icon.ms-Check-circle,
.ms-Icon.ms-Check-check {
    display: inline-flex;
}

.app {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.app_content {
    flex: 1 0;
    display: block;
}

.center-content {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* Command bar inside details list view row */
.list-inline-command-bar .ms-CommandBar {
    height: 24px;
    padding: 0;
    background-color: transparent;
}
.list-inline-command-bar .ms-Button--commandBar {
    padding: 0;
    min-width: 32px;
    width: 32px;
    margin-top: -6px;
    margin-bottom: -6px;
    height: 36px;
}
.list-inline-command-bar .ms-Button--commandBar:not(:hover):not(:active) {
    background-color: transparent;
}

/*
    See: https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
    This works because:
    1. The main flex container has a relative or absolute position and is allowed to grow
       its height to the space available in it's parent. This creates a flex container of a fixed height which we need
       for scroll bars to work.
    2. The content flex container is also set to grow to fill the positioned container and sets a flex layout for it's children.
    3. Then this style can be applied to the container you want to grow to the remaining available vertical space
       of the positioned container while also allowing the content inside this container to grow large enough to fit all of it.
       If the content height is larger than this container a scroll bar is shown.

    See lab-list.css and lab-list.tsx for how this is used in practice.
*/
.vertical-scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
}

/*
    This works because:
    1. The main flex container has a relative or absolute position and is allowed to grow
       its height to the space available in it's parent. This creates a flex container of a fixed height which we need
       for scroll bars to work.
    2. The content flex container is also set to grow to fill the positioned container and sets a flex layout for it's children.
    3. Then this style can be applied to the container you want to grow to the remaining available vertical space
       of the positioned container while preventing it front growing anymore than that if the content inside it would cause it to
       scroll.

    See schedules.css and schedules.tsx for how this is used in practice.
*/
.dynamic-fixed-size-no-scroll {
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
}

.custom-link-button {
    text-decoration: none;
}

.custom-link-button:disabled {
    color: #a19f9d;
    text-decoration: none;
}

.custom-link-button:hover {
    text-decoration: none;
}

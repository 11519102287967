#template-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#template-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#template-header {
    height: fit-content;
    width: 100%;
}

.template__page-header {
    margin-bottom: 30px;
}

.template__card {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 750px;
    border: 1px solid transparent;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
    border-radius: 2px;
}

.template__publish-status {
    font-size: 12px;
}

.template__image-status {
    font-size: 12px;
    color: #787673;
}

.template__image-status--failed {
    max-width: 450px;
    display: 'inline-block';
    text-align: end;
}

.template__size-divider {
    padding-left: 5px;
    padding-right: 5px;
}

.template__power-status {
    font-size: 12px;
}

.template__image {
    font-weight: 600;
    padding-right: 15px;
}

.template__card-wrapper {
    padding-left: 30px;
}

.template__card-text-field-wrapper {
    margin-top: 25px;
}

.template__reset-password-dialog--username {
    font-weight: 600;
}

.template__customize-dialog--linux-rdp {
    margin-bottom: 10px;
}

.template__status-container {
    width: 300px;
}

#template-page-size-info {
    min-height: 19px;
}

#template-page-price-info {
    min-height: 19px;
}

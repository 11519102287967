#vm-list-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#vm-list-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#vm-list-header {
    height: fit-content;
    width: 100%;
}

#vm-list-body {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 25px;
    margin-right: 25px;
}

/* HACK: Using these funky styles to remove weird placeholder padding styles */
.vm-list__filter-button .ms-ContextualMenu-icon {
    display: none;
}

.vm-list__filter-clear-button .ms-Icon-placeHolder {
    display: none;
}

.vm-list__row {
    padding: 0;
}

.vm-list__name-column {
    padding: 0;
}

.vm-list__state-column {
    padding: 0;
}

.vm-list__name-stack {
    height: 100%;
    width: 100%;
}

.vm-list__name-text-stack-item {
    height: 100%;
    max-width: calc(100% - 64px);
}

.vm-list__name-action-stack-item {
    height: 100%;
    width: 32px;
}

.vm-list__state-stack {
    width: 100%;
    height: 100%;
}

.vm-list__state-stack-item {
    height: 100%;
}

.vm-list__action-selected {
    display: flex;
}

.vm-list__action-selected-hidden {
    display: none;
}

.vm-list__action-selected:hover {
    display: flex;
    background-color: #d2d0ce;
}

.vm-list__action-not-selected {
    display: none;
}

.vm-list__action-not-selected-hidden {
    display: none;
}

.vm-list__row:hover .vm-list__action-not-selected {
    display: flex;
}

.vm-list__row:hover .vm-list__action-not-selected:hover {
    background-color: #edebe9;
}

.vm-list__user-select {
    user-select: text;
}

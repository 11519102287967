.reset-password-dialog {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.password-dialog .ms-Label {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
}

.reset-password-dialog-password-field-container {
    max-width: 360px;
    padding-top: 27px;
}

.reset-password-dialog-password-complexity-requirements {
    padding-top: 27px;
}

#dashboard-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#dashboard-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#dashboard-header {
    height: fit-content;
    width: 100%;
}

#dashboard-overview-cards-section {
    margin-top: 50px;
    padding-bottom: 10px;
}

.dashboard__cost-estimate-tile--disclaimer {
    margin: 18px 0px 0px 36px;
    line-height: 16px;
    font-size: 12px;
    color: #605e5c;
    max-width: 678px;
}

.dashboard__cost-estimate-tile--title {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 70px;
    height: 70px;
    padding: 0px;
    margin: 0px;
}

.dashboard__overview-card--single-column-row {
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard__overview-card--info-container {
    margin-left: 25px;
    margin-right: 25px;
}

.dashboard__overview-card--info-row {
    display: table-row;
}

.dashboard__overview-card--info-column-one {
    display: table-cell;
    padding-right: 10px;
}

.dashboard__overview-card--info-column-two {
    display: table-cell;
    font-weight: 600;
}

#cost-estimate-tile-error-message {
    padding-left: 25px;
}

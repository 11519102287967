#page-header-container {
    width: 100%;
}

.page-header__separator {
    height: 100%;
    padding: 0 5px 0 5px;
    display: flex;
    align-items: center;
    justify-items: center;
    vertical-align: middle;
}

#page-header-container .ms-OverflowSet-item ::after {
    border: #ffffff solid 1px;
}

.picker__bubble {
    border-radius: 15px;
    width: fit-content;
    padding: 1px 5px 2px 5px;
    margin-left: 6px;
    font-size: 10px;
    font-weight: normal;
}

.picker__bubble-selected {
    background-color: #484644;
}

.picker__bubble-item {
    background-color: #f3f2f1;
}

button.is-expanded .picker__bubble-selected {
    background-color: #e1dfdd;
}

/* Styles specific for Teams and LMS */
.picker__bubble-selected-teams-lms {
    background-color: #e1dfdd;
}

.picker__bubble-item-teams-lms {
    background-color: #f3f2f1;
}

button.is-expanded .picker__bubble-selected-teams-lms {
    background-color: #c8c6c4;
}

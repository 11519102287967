.publish-template-dialog__publish {
    margin-top: 15px;
}

.publish-template-dialog__number-can-be-changed-text {
    margin-top: 18px;
}

.publish-template-dialog__this-takes-a-long-time-text {
    margin-top: 42px;
}

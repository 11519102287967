.cardContainer {
    width: 100%;
}

.cardContainer .current {
    transform: translateX(0);
}

.cardContainer .movingForwards-previous {
    transform: translateX(-100%);
}

.cardContainer .movingForwards-next:not(.current) {
    transform: translateX(100%);
}

.cardContainer .movingBackwards-previous {
    transform: translateX(100%);
}

.cardContainer .movingBackwards-next:not(.current) {
    transform: translateX(-100%);
}

.card {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;
}

.ariaCard {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

#full-page-message-container {
    max-width: 520px;
    max-width: 80%;
    text-align: center;
}

.full-page-message__icon {
    height: auto;
    width: auto;
}

.full-page-message svg {
    height: 300px;
    width: 300px;
}

.full-page-message__message,
.full-page-message__details,
.full-page-message__link {
    padding-bottom: 10px;
}

#lab-app-nav {
    width: fit-content;
    white-space: nowrap;
    background-color: #f3f2f1;
    color: #323130;
    display: flex;
}

#lab-app-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.copy-to-clipboard-button-icon-container {
    background-color: #eaeaea;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copy-to-clipboard-button-text-container {
    margin-left: 8px;
}

#schedule-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#schedule-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

#schedule-body .fc {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin: 30px 30px 0px 30px;
}

.schedules__flyout-control {
    margin-bottom: 10px;
}

.schedules__flyout-control--header {
    margin-top: 25px;
    margin-bottom: 10px;
}

.schedules__flyout-control--label {
    margin-bottom: 5px;
}

.schedule__flyout-error {
    color: rgb(168, 0, 0);
    font-size: 12px;
    font-weight: 400;
    padding-top: 5px;
}

.schedule__flyout--type-info {
    margin-top: 20px;
    font-size: smaller;
}

.schedules__flyout-daypicker-error {
    color: rgb(168, 0, 0);
    border: 1px solid rgb(168, 0, 0);
}

.schedules__event-callout button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link:not(:hover) {
    background-color: transparent;
}

.schedules__event-callout--notes {
    padding-bottom: 25px;
    font-size: 12px;
}

.schedules__flyout--repeat-delete {
    display: none;
}

.schedules__flyout--repeat:focus-within .schedules__flyout--repeat-delete {
    display: inline;
}

.schedules__flyout--repeat:hover .schedules__flyout--repeat-delete {
    display: inline;
}

.schedules__flyout-control label.ms-Label,
.schedules__flyout-control--header label.ms-Label {
    font-weight: 500;
}

.schedules__recurrence-datepicker {
    display: inline-block;
}

.schedules__recurrence-datepicker .ms-TextField-field,
.schedules__recurrence-datepicker .ms-DatePicker-event--without-label {
    color: #0078d4;
}

.schedules__recurrence-datepicker .ms-TextField-field {
    padding-left: 0px;
    padding-right: 0px;
}

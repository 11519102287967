html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0px;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

.ms-Fabric {
    display: inline;
}

#student-vm-list {
    flex: 1 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}

#student-vm-list-page-title {
    height: 27px;
    font-size: 20px;
    line-height: 27px;
    margin-top: 46px;
    padding: 0 32px;
    font-weight: 600;
}

#student-vm-list-loading {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#student-vm-list-content {
    padding: 18px 22px;
}

.carouselPagination .dot {
    margin: 5px;
    font-size: 12px;
    color: #e1dfdd;
    cursor: pointer;
}

.carouselPagination .activeDot {
    color: #0078d4;
}

#page-container {
    display: flex;
    flex-direction: column;
}

.page-container-fixed {
    height: calc(100vh);
    width: calc(100vw);
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.page-container-scrollable {
    height: 100%;
    width: 100%;
    overflow: auto;
}

#page-content {
    flex-grow: 1;
    width: 100%;
}

.page-content-scrollable {
    height: max-content;
}

.page-content-fixed {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

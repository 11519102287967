#landing-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#landing-page-header-section {
    text-align: center;
    height: max-content;
}

#landing-page-header-title {
    padding: 0 5px 0 5px;
}

#landing-page-header-text {
    max-width: 615px;
    padding: 0 5px 0 5px;
}

#landing-page-header-icon {
    height: 330px;
    width: 500px;
    padding-top: 30px;
    padding-bottom: 20px;
}

#landing-page-header-existing-user {
    padding-top: 30px;
}

#landing-page-instructions-container {
    background-color: #faf9f8;
}

#landing-page-instructions-section {
    padding-bottom: 45px;
    width: 100%;
    height: max-content;
}

#landing-page-instructions-heading-section {
    padding-top: 35px;
}

#landing-page-instructions-content-section {
    width: 100%;
    height: max-content;
}

.landing-page__instructions-item {
    text-align: center;
    flex: 1 1 0;
}

.landing-page__instructions-icon-container {
    width: 276px;
    height: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-page__instructions-icon-container svg {
    width: 220px;
    height: 147px;
}

.landing-page__getting-started-summary-format {
    padding: 24px 0;
    width: 50%;
}

.landing-page__getting-started-section-number {
    flex: none;
    color: #007ad7;
    padding: 5px;
    vertical-align: sub;
}

.landing-page__getting-started-heading-format {
    line-height: normal;
    padding: 35px 0;
}

.landing-page__getting-started-action-statement {
    padding-bottom: 40px;
}

@media only screen and (max-width: 599px) {
    #landing-page-header-icon {
        height: 230px;
        width: 400px;
    }

    #landing-page-header-title {
        font-size: 28px;
    }
}

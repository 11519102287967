.student-vm-card__header {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    max-height: 50px;
    align-items: center;
}

.student-vm-card__header-title-container {
    display: flex;
    align-items: center;
    order: 1;
    width: 80%;
    height: 50px;
    padding-right: 0px;
}

.student-vm-card__header-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    width: 20%;
    height: 50px;
}

.student-vm-card__bubble {
    background-color: #edebe9;
    border-radius: 15px;
    width: fit-content;
    padding: 1px 6px 3px 6px;
    margin-left: 12px;
    font-size: 12px;
    font-weight: normal;
}

.student-vm-card__content-description {
    height: 65px;
}

.student-vm-card__content-hours-used {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 8px 16px;
    display: flex;
    flex-direction: column;
}

.student-vm-card__progress-bar-container {
    height: 10px;
    padding-bottom: 6px;
}

.student-vm-card__content-hours-note {
    flex-direction: row;
    display: flex;
    padding-top: 2px;
}

.student-vm-card__change-password-status-container {
    padding-top: 2px;
}

.student-vm-card__logo {
    order: 2;
    justify-self: right;
}

.student-vm-card__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 41px;
}

.student-vm-card__footer-message {
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.student-vm-card__footer-status-text {
    padding-bottom: 1px;
}

.student-vm-card__footer .ms-DocumentCardActions-action button {
    background-color: transparent;
}

.student-vm-card__footer-more-menu {
    margin-left: auto;
}

.student-vm-card__footer-more-menu--placeholder {
    height: 42px;
}

.student-vm-card__divider {
    margin: 0 6px;
    height: 1px;
}

.student-vm-card__content {
    flex-grow: 1;
}

.student-vm-card__progress-bar-tooltip-container .ms-Tooltip-content {
    font-size: 12px;
    line-height: 16px;
}

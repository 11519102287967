.troubleshoot-dialog {
    width: auto;
    max-width: 827px;
}

.troubleshoot-dialog .ms-Dialog-header {
    padding-bottom: 10px;
}

.troubleshoot-dialog .ms-Dialog-inner {
    padding: 0px 42px 35px 24px;
}

.troubleshoot-dialog .ms-Dialog-actionsRight {
    text-align: left;
    margin-left: -4px;
}

#no-vms-message-container {
    max-width: 821px;
    padding: 5px;
}

#no-vms-message-header-container {
    padding-bottom: 40px;
}

#no-vms-message-icon-container {
    padding-right: 33px;
}

#no-vms-message-instructions-container {
    max-width: 488px;
    padding-bottom: 30px;
}

#no-vms-message-create-a-lab-section {
    padding-bottom: 52px;
}

#no-vms-message-create-a-lab-section-title {
    padding-bottom: 10px;
}

#no-vms-message-registration-section-title {
    padding-bottom: 10px;
}

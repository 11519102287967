.export-sig-dialog--emphasis {
    font-weight: 600;
}

.export-sig-dialog--summary {
    margin-bottom: 18px;
}

.export-sig-dialog--summary a {
    text-decoration: underline;
}

.export-sig-dialog__export_create--text {
    margin-top: 48px;
}

.export-sig-dialog__re-export_heavy-info {
    font-weight: 600;
    padding-left: 5px;
}

.export-sig-dialog__re-export_create--text {
    margin-top: 32px;
}

.export-sig-dialog__re-export_create--name-field {
    padding-left: 25px;
    padding-top: 13px;
    padding-bottom: 15px;
}

.export-sig-dialog__re-export_update--existing-name {
    padding-left: 5px;
    color: #666666;
}

.user-menu__activity-item-name {
    font-weight: bold;
    font-size: 18px;
    color: #323130;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 38px;
    margin-left: 110px;
}

.user-menu__activity-item-display-id {
    font-size: 14px;
    color: #323130;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-left: 110px;
}

.user-menu__activity-item-link {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 110px;
}
